// Import libraries
import React, { useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Import navigation context
import NavigationContext from '../context/navigation';


// Component styles
const StyledNavigation = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    
    & .navigation__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, .5);
    }

    & .navigation__inner {
        display: grid;
        grid-template-rows: 1fr auto;
        grid-row-gap: 3rem;
        align-items: center;
        justify-items: center;
        min-height: 100vh;
        width: 400px;
        background-color: #fff;
        padding-top: 12rem;
        padding-bottom: 5rem;

        /* media query breakpoint of 400px is used in animation on .navigation__inner */
        @media only screen and (max-width: 400px) {
            width: 100vw;
        }
    }

    & .navigation__items-list {
        display: grid;
        grid-template-rows: repeat(6, 5rem);
        align-items: center;
        grid-row-gap: 3rem;
        list-style: none;
        width: 25rem; /* same width as .navigation__footer */
    }

    & .navigation__item {
        font-size: 3.5rem;
        font-weight: bold;
        color: #6c6f77;

        &-link:link,
        &-link:visited {
            color: currentColor;
            text-decoration: none;
            font-weight: bold;
            outline: 0;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: var(--color-primary);
            outline: 0;
        }

        &-link:active {
            text-decoration: none;
            color: #000;
        }

        &-link--active:link,
        &-link--active:visited,
        &-link--active:active {
            color: #000;
        }


        &-link--active:hover,
        &-link--active:focus {
            color: var(--color-primary);
        }
    }

    & .navigation__footer {
        display: grid;
        grid-row-gap: 1rem;
        width: 25rem; /* same width as .navigation__items-list */

        &-title {
            font-size: 2rem;
            font-weight: bold;
            color: var(--color-primary);
        }

        &-link {
            display: grid;
            grid-template-columns: min-content 1fr;
            grid-column-gap: 1rem;
            font-weight: 100;
        }

        &-link:link,
        &-link:visited {
            color: #7a7d84;
            text-decoration: none;
            outline: 0;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: var(--color-primary);
            outline: 0;
        }

        &-link:active {
            text-decoration: none;
            color: #000;
        }
    }
`;


// Create component
const Navigation = () => {
    const { navigationIsOpen, setNavigationIsOpen, viewportSize } = useContext(NavigationContext);


    // Get data
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    phoneNumber
                    whatsappNumber
                    email
                }
            }
        }
    `);


    // Handle menu item on click
    const handleMenuItemOnClick = () => {
        // Close menu
        setNavigationIsOpen(false);
    };


    return (
        <StyledNavigation
            animate={navigationIsOpen ? { zIndex: 1 } : { zIndex: -1 }}
            initial={navigationIsOpen ? { zIndex: 1 } : { zIndex: -1 }}
            transition={!navigationIsOpen && { delay: .5 }}
        >
            <motion.div
                animate={navigationIsOpen && viewportSize.width >= 400 ? { opacity: 1 } : { opacity: 0 }}
                initial={navigationIsOpen && viewportSize.width >= 400 ? { opacity: 1 } : { opacity: 0 }}
                transition={{
                    type: 'spring',
                    damping: 100,
                    stiffness: 200
                }}
                className="navigation__overlay"
                onClick={() => setNavigationIsOpen(false)}
            >&nbsp;</motion.div>

            <motion.div
                animate={navigationIsOpen ? { x: viewportSize.width < 400 ? 0 : "calc(100vw - 400px)" } : { x: "100vw" }}
                initial={navigationIsOpen ? { x: viewportSize.width < 400 ? 0 : "calc(100vw - 400px)" } : { x: "100vw" }}
                transition={{
                    type: 'spring',
                    damping: 100,
                    stiffness: 200
                }}
                className="navigation__inner"
                onClick={(e) => e.stopPropagation()}
            >
                <nav>
                    <ul className="navigation__items-list">
                        <li className="navigation__item">
                            <Link
                                to="/"
                                onClick={handleMenuItemOnClick}
                                className="navigation__item-link"
                                activeClassName="navigation__item-link--active">Home
                            </Link>
                        </li>

                        <li className="navigation__item">
                            <Link
                                to="/about-us"
                                onClick={handleMenuItemOnClick}
                                className="navigation__item-link"
                                activeClassName="navigation__item-link--active">About Us
                            </Link>
                        </li>

                        <li className="navigation__item">
                            <Link
                                to="/our-services"
                                onClick={handleMenuItemOnClick}
                                className="navigation__item-link"
                                activeClassName="navigation__item-link--active">Our Services</Link>
                        </li>

                        {/*<li className="navigation__item">
                            <Link
                                to="/klantenreacties"
                                onClick={handleMenuItemOnClick}
                                className="navigation__item-link"
                                activeClassName="navigation__item-link--active">Klantenreacties</Link>
                        </li>*/}

                        <li className="navigation__item">
                            <Link
                                to="/contact"
                                onClick={handleMenuItemOnClick}
                                className="navigation__item-link"
                                activeClassName="navigation__item-link--active">Contact</Link>
                        </li>
                    </ul>
                </nav>

                <div className="navigation__footer">
                    <span className="navigation__footer-title">Contact Us</span>
                    <a href={`tel:${site.siteMetadata.phoneNumber}`} className="navigation__footer-link">
                        <FontAwesomeIcon icon={faPhoneAlt} />
                        {site.siteMetadata.phoneNumber}
                    </a>
                    <a href={`whatsapp:${site.siteMetadata.whatsappNumber}`} className="navigation__footer-link">
                        <FontAwesomeIcon icon={faWhatsapp} />
                        {site.siteMetadata.whatsappNumber}
                    </a>
                    <a href={`mailto:${site.siteMetadata.email}`} className="navigation__footer-link">
                        <FontAwesomeIcon icon={faEnvelope} />
                        {site.siteMetadata.email}
                    </a>
                </div>
            </motion.div>
        </StyledNavigation>
    );
};


// Export component by default
export default Navigation;