// Import libraries
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Component styles
const StyledFooter = styled.footer`
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, max-content);
    grid-row-gap: 10rem;
    justify-items: start;
    justify-content: center;
    align-items: center;
    padding-top: 100px; /* Equal to top .scroll-to-top-button */
    padding-bottom: 100px;
    background-color: var(--color-background-1);
    color: #fff;

    & .content {
        position: relative;
        display: grid;
        grid-template-rows: repeat(3, max-content);
        grid-row-gap: 5rem;
        max-width: 1100px;
        padding: 0 var(--padding-right) 0 var(--padding-left);

        & .scroll-to-top-button {
            position: absolute;
            top: -100px; /* Equal to padding-top of StyledFooter */
            left: 0;
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            margin-left: var(--padding-left);
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }

        & h2 {
            font-size: var(--font-size-footer-h2);
            font-weight: var(--font-weight-footer-h2);
            line-height: var(--line-height-footer-h2);
        }

        & p {
            color: #afafaf;
            margin-top: -3rem;
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }

        &-external-link:link,
        &-external-link:visited {
            color: currentColor;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        &-external-link:hover,
        &-external-link:focus {
            text-decoration: none;
            color: var(--color-primary);
            outline: 0;
        }

        &-external-link:active {
            text-decoration: none;
            color: currentColor;
        }

        &-internal-link {
            justify-self: start;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;

            & span {
                margin-right: 10px;
            }
        }

        &-internal-link:link,
        &-internal-link:visited {
            color: currentColor;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        &-internal-link:hover,
        &-internal-link:focus {
            text-decoration: none;
            color: currentColor;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        &-internal-link:active {
            text-decoration: none;
            color: currentColor;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }

    & .footer__navigation {
        display: grid;
        grid-template-columns: repeat(4, max-content);
        grid-column-gap: 2rem;
        justify-self: start;
        list-style: none;
        color: #afafaf;
        padding: 0 var(--padding-right) 0 var(--padding-left);

        &-link:link,
        &-link:visited {
            color: currentColor;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
            font-size: var(--font-size-footer-a);
            font-weight: var(--font-weight-footer-a);
            line-height: var(--line-height-footer-a);
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: var(--color-primary);
            outline: 0;
        }

        &-link:active {
            text-decoration: none;
            color: currentColor;
        }
    }
`;


// Create component
const Footer = () => {
    // Handle scrolling to top of page
    const handleScrollToTop = () => {
        // Scroll to top of page
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };


    return (
        <StyledFooter>
            <div className="content">
                <div className="scroll-to-top-button" onClick={handleScrollToTop}>
                    <FontAwesomeIcon icon={faArrowUp} className="icon" />
                </div>
                
                <h2>Are you interested in working with us?</h2>
                
                <Link to="/contact" className="content-internal-link">
                    <span>Contact Us</span>
                    <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                </Link>
            </div>

            <ul className="footer__navigation">
                <li>
                    <Link to="/privacy" className="footer__navigation-link">Privacy Policy</Link>
                </li>
                <li>
                    <Link to="/disclamer" className="footer__navigation-link">Disclamer</Link>
                </li>
            </ul>
        </StyledFooter>
    );
};


// Export component by default
export default Footer;